import React, { useMemo } from 'react'

import { useInView } from 'react-intersection-observer'

import { FrankieButton, FrankieIconName } from 'frankify/src'

import { INDIVIDUAL_PROFILE_INSIGHT_KEY } from 'features/individual-profile-insight/individual-profile-insight.key'
import { individualProfileInsightEn } from 'features/individual-profile-insight/locale/individual-profile-insight.en'

import { useFilteredAttachments } from 'entities/document-f2'
import { useEntityLabelDataState } from 'entities/entity'

import { formatDate, DateFormatTypes } from 'shared/date-time'
import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import {
  TrackingEventsTypes,
  trackingManager,
  useTrackingShowEvents,
} from 'shared/tracking'

import { IndividualOcrOverlay } from './individual-ocr-overlay/individual-ocr-overlay'

type Props = {
  entityId: string
}

type ActionButtonProps = {
  onClick: () => void
  iconName: FrankieIconName
}

function ActionButton({ onClick, iconName }: ActionButtonProps) {
  return (
    <FrankieButton
      noStyles
      className="p-1.5"
      onClick={onClick}
      singleIcon={{ name: iconName, size: 'sm' }}
    />
  )
}

export function IndividualProfileSelfie({ entityId }: Props) {
  const { ref, inView } = useInView()
  const [createOverlay] = useOverlay()
  const { documentWithLabelData: documents } = useEntityLabelDataState({
    entityId,
  })

  const { selfies, setImageURL, filterMostRecentAttachments } =
    useFilteredAttachments(documents)

  const selfieAttachments = setImageURL(selfies)
  const documentAttachments = useMemo(
    () =>
      filterMostRecentAttachments(documents).map(doc => ({
        ...doc,
        attachments: setImageURL(doc.attachments),
      })),
    [documents, filterMostRecentAttachments, setImageURL],
  )

  const t = useI18n([INDIVIDUAL_PROFILE_INSIGHT_KEY], {
    keys: individualProfileInsightEn,
  })

  const { triggerEvent } = useTrackingShowEvents({
    eventsType: TrackingEventsTypes.ProfileSelfieScroll,
  })

  useTrackingShowEvents({
    data: inView,
    eventsType: TrackingEventsTypes.ProfileInsightsSelfieViewIndividual,
  })

  const handleExpand = (activeIndex: number) => () => {
    trackingManager.track(TrackingEventsTypes.ProfileSelfieCompareClick)
    createOverlay(
      <IndividualOcrOverlay
        defaultIndex={activeIndex}
        docs={documentAttachments}
        selfies={selfieAttachments}
      />,
      { className: '!p-6', closeButtonClassName: '!top-6 !right-6' },
    )
  }

  const handleDownload = (url: string) => async () => {
    try {
      const blob = await fetch(url).then(r => r.blob())
      const imageUrl = URL.createObjectURL(blob)
      const link = document.createElement('a')
      const isUrlNameCorrect = (url.split('.').at(-1)?.length ?? 0) <= 3

      link.href = imageUrl
      link.download = isUrlNameCorrect ? url : 'download.jpg'
      link.target = '_blank'
      link.click()
    } catch (error) {
      // eslint-disable-next-line no-console -- we need to log the error
      console.error('Error downloading the file:', error)
    }
  }

  const recentUploadedOn = selfies.reduce(
    (acc, selfie) => {
      const selfieDate = new Date(selfie.createdAt)
      const accDate = new Date(acc.createdAt)

      if (selfieDate > accDate || selfieDate.getTime() === accDate.getTime()) {
        return {
          createdAt: selfie.createdAt,

          date: formatDate(
            selfie.createdAt,
            DateFormatTypes.DateNumbersSlash,
          ).trimEnd(),

          time: formatDate(
            selfie.createdAt,
            DateFormatTypes.Time24HoursWithSeconds,
          ).trimEnd(),
        }
      }
      return acc
    },
    { date: '', time: '', createdAt: '1970' },
  )

  if (!selfieAttachments.length) {
    return null
  }

  return (
    <div ref={ref} className="flex flex-col gap-y-3">
      <h1 className="font-bold text-tertiary-grey-800 text-xl justify-between flex items-center gap-1">
        {t('tab.selfies')}
        <p className="text-xs font-normal text-tertiary-grey-500">
          {t('modal.recentUploadedOn', {
            time: recentUploadedOn.time,
            date: recentUploadedOn.date,
          })}
        </p>
      </h1>

      <div
        onScroll={() => triggerEvent(true)}
        className="w-full pb-4 flex gap-3 scrollbar overflow-x-auto"
      >
        {selfieAttachments.map((selfie, index) => (
          <div
            key={selfie.attachmentId}
            className="flex flex-col shrink-0 grow-0 gap-2 basis-60 bg-tertiary-grey-200 rounded-sm p-2"
          >
            {selfie.isImage ? (
              <div className="w-full aspect-square overflow-hidden">
                <img
                  alt="selfie"
                  src={selfie.url}
                  className="w-full h-full object-cover"
                />
              </div>
            ) : (
              <div className="w-full aspect-square">
                <video
                  loop
                  controls
                  key={selfie.attachmentId}
                  className="w-full h-full object-cover"
                >
                  <track kind="captions" />
                  <source src={selfie.url} type="video/mp4" />
                </video>
              </div>
            )}
            <div className="flex justify-between">
              <ActionButton
                iconName="mdiArrowExpand"
                onClick={handleExpand(index)}
              />
              <ActionButton
                iconName="mdiDownload"
                onClick={handleDownload(selfie.url)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
