import React, { useState } from 'react'

import { FrankieButton, FrankieImage } from 'frankify/src'

import { TrackingEventsTypes, trackingManager } from 'shared/tracking'

import { Media } from './media'

export type GalleryImage = {
  url: string
  side: 'FRONT' | 'BACK'
  mimeType: string
  id: string
  type: 'PDF' | 'IMAGE' | 'VIDEO'
  createdAt: string
}
type DocumentType = {
  gallery: GalleryImage[]
  className?: string
  handleExpand: (idx?: number) => void
  trackingEvents?: TrackingEventsTypes[]
  expandable?: boolean
}

export function DocumentThumbs({
  gallery,
  className = '',
  handleExpand,
  trackingEvents,
  expandable = true,
}: DocumentType) {
  const [imgIdx, setImgIdx] = useState(0)

  const handleImageChange = (isNext: boolean) => () => {
    if (isNext) {
      setImgIdx(prev => {
        const idx = prev + 1
        if (trackingEvents?.[idx]) trackingManager.track(trackingEvents[idx])
        return idx
      })
    } else {
      setImgIdx(prev => {
        const idx = prev - 1
        if (trackingEvents?.[idx]) trackingManager.track(trackingEvents[idx])
        return idx
      })
    }
  }

  const hasControls = gallery.length > 1
  const activeItem = gallery[imgIdx]

  return (
    <div className="flex flex-col gap-3 h-full">
      <div
        className={`rounded-md relative overflow-hidden w-full border border-tertiary-grey-300 bg-tertiary-grey-200 min-h-[150px] ${className}`}
        role="button"
        tabIndex={0}
        onClick={expandable ? () => handleExpand(imgIdx) : undefined}
        onKeyDown={
          expandable
            ? e => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleExpand(imgIdx)
                }
              }
            : undefined
        }
      >
        {gallery.length > 0 ? (
          <Media mimeType={activeItem.mimeType} url={activeItem.url} />
        ) : (
          <FrankieImage
            alt="Document"
            src="frankie_individual_document_placeholder"
            className="w-full h-full object-contain"
          />
        )}
        <div className="absolute pointer-events-none w-full h-full top-0 left-0 bg-gradient-to-t from-mono-black to-transparent from-0% to-35% opacity-25" />
        {expandable && (
          <div className="h-7 w-7 rounded-sm bg-mono-white flex justify-center items-center absolute bottom-2 left-2 ">
            <FrankieButton
              intent="subtle"
              noStyles
              singleIcon={{
                name: 'mdiArrowExpand',

                size: 'xs',
              }}
              onClick={() => handleExpand(imgIdx)}
            />
          </div>
        )}
      </div>

      <div className="flex justify-between items-center w-full grow-0">
        {hasControls && (
          <div className="border border-tertiary-grey-200 rounded-l-sm flex justify-center items-center grow-0">
            <FrankieButton
              intent="subtle"
              disabled={imgIdx === 0}
              className="h-7 !min-w-[28px]"
              singleIcon={{
                name: 'mdiChevronLeft',
                size: '2xs',
              }}
              onClick={handleImageChange(false)}
            />
          </div>
        )}
        {gallery.length > 1 && (
          <p className="flex-1 text-center font-medium text-xs leading-4 text-tertiary-grey-500 lowercase first-letter:uppercase">
            {activeItem.side}
          </p>
        )}
        {hasControls && (
          <div className="border border-tertiary-grey-200 rounded-r-sm flex justify-center items-center grow-0">
            <FrankieButton
              intent="subtle"
              className="h-7 !min-w-[28px]"
              disabled={imgIdx === gallery.length - 1}
              singleIcon={{
                name: 'mdiChevronRight',
                size: '2xs',
              }}
              onClick={handleImageChange(true)}
            />
          </div>
        )}
      </div>
    </div>
  )
}
