import React from 'react'

import './tooltip.css'

import { Tooltip, TooltipContent, TooltipTrigger } from './tooltip-floating-ui'
import {
  FrankieTooltipNoFloatingStyle,
  FrankieTooltipStyle,
} from './tooltip.theme'
import { IFrankieTooltipProps } from './tooltip.types'
import { enableTailwindClass } from '../../styles/tailwind/enable-tailwind-class'

export function FrankieTooltip({
  title,
  body,
  children,
  className,
  textClassName = '',
  position,
  zIndex = 0,
  hidden = false,
  noFloating = false,
  testId = {
    tooltip: '',
  },
}: IFrankieTooltipProps) {
  if (noFloating) {
    return (
      <div className="frankie-tooltip-container relative w-fit">
        <div
          data-qa={testId.tooltip}
          className={FrankieTooltipNoFloatingStyle({ className, position })}
        >
          {title && (
            <div className="mb-1.5 text-mono-white leading-none text-sm font-medium">
              {title}
            </div>
          )}
          {body && (
            <div className="text-tertiary-grey-400 leading-tight text-xs">
              {body}
            </div>
          )}
        </div>
        {children}
      </div>
    )
  }

  return (
    <Tooltip placement={position}>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      {!hidden && (
        <TooltipContent
          className={enableTailwindClass}
          style={{ ...(zIndex && { zIndex }) }}
        >
          <div
            data-qa={testId.tooltip}
            className={FrankieTooltipStyle({
              position,
              className,
            })}
          >
            {title && (
              <div
                className={`${
                  body ? 'mb-1.5' : ''
                } text-mono-white leading-none text-sm font-medium ${textClassName}`}
              >
                {title}
              </div>
            )}
            {body && (
              <div className="text-tertiary-grey-400 leading-tight text-xs">
                {body}
              </div>
            )}
          </div>
        </TooltipContent>
      )}
    </Tooltip>
  )
}
