import React from 'react'

import cv from 'classnames'
import { useInView } from 'react-intersection-observer'

import {
  FrankieBadge,
  FrankieButton,
  FrankieDivider,
  FrankieLoader,
} from 'frankify/src'

import { MutateIndividualResultsType } from 'features/individual-idv-check/model/individual-results.model'
import {
  IndividualResultsTypes,
  useUpdateIndividualResultsMutation,
} from 'features/individual-idv-check/mutation/update-results.mutation'

import { CommentHOC, CommentsTypes, CommentToolTip } from 'entities/comments'
import { ProcessResultManualStatusEnumKYC } from 'entities/entity'
import { PermissionTypes } from 'entities/role'
import { useGlobalAppState } from 'entities/routing'
import { useHasPermission } from 'entities/session'

import { useI18n } from 'shared/i18n'
import { useOverlay } from 'shared/overlay'
import { TrackingEventsTypes, useTrackingShowEvents } from 'shared/tracking'

import { INDIVIDUAL_OCR_CHECK_KEY } from '../../individual-ocr-check.key'
import { individualOcrCheckEn } from '../../locale/individual-ocr-check.en'
import {
  borderStyleConfig,
  IconConfig,
} from '../../model/individual-idv-check.model'
import { useIndividualOcrCheckState } from '../../state/individual-ocr-check/individual-ocr-check.state'
import { OcrDocumentRow } from '../individual-ocr-check-document-row/individual-ocr-check-document-row'
import {
  ManualStatusChangeForm,
  UpdateStatusType,
} from '../individual-ocr-form/individual-ocr-form'
import { IndividualOcrGallery } from '../individual-ocr-gallery/individual-orc-gallery'

import './individual-ocr-check.css'

function ManualStatusBadge({
  status,
  textFailed,
  textPassed,
}: {
  status: ProcessResultManualStatusEnumKYC
  textFailed: string
  textPassed: string
}) {
  return (
    <FrankieBadge
      theme={
        status === ProcessResultManualStatusEnumKYC.REJECTED ? 'red' : 'green'
      }
      text={
        status === ProcessResultManualStatusEnumKYC.REJECTED
          ? textFailed
          : textPassed
      }
    />
  )
}

type Props = {
  entityId: string
  idx: number
}

export function IndividualOcrCheck({ entityId, idx }: Props) {
  const {
    hadOcrChecks,
    status,
    ocrCheckResults,
    manualStatus,
    requireResolve,
    gallery,
    processResultIds,
    flags,
    isLoading,
  } = useIndividualOcrCheckState({
    entityId,
    idx,
  })

  const { canOverrideStatus } = useHasPermission({
    canOverrideStatus: PermissionTypes.ApplicantNew,
  })

  const [createOverlay, closeOverlay] = useOverlay()
  const { data } = useGlobalAppState()
  const t = useI18n([INDIVIDUAL_OCR_CHECK_KEY], { keys: individualOcrCheckEn })
  const { ref, inView } = useInView()
  useTrackingShowEvents({
    data: hadOcrChecks && inView,
    eventsType: TrackingEventsTypes.WorkflowEventsBiometricsShow,
  })

  const { mutateAsync, isLoading: isUpdating } =
    useUpdateIndividualResultsMutation({
      entityId,
      type: IndividualResultsTypes.IDV,
    })

  const updateResultStatus = async (data: UpdateStatusType) => {
    const payload: MutateIndividualResultsType = {
      processResults: processResultIds,
      manualStatus: data.manualStatus,
      comment: {
        text: data.comment,
      },
    }
    closeOverlay()
    await mutateAsync(payload)
  }

  const handleClick = () => {
    createOverlay(
      <ManualStatusChangeForm
        options={[
          {
            label: t('manualPassStatus'),
            value: ProcessResultManualStatusEnumKYC.CLEAR,
          },
          {
            label: t('manualFailStatus'),
            value: ProcessResultManualStatusEnumKYC.REJECTED,
          },
        ]}
        closeOverlay={closeOverlay}
        updateWorkflowStatus={updateResultStatus}
      />,
      {
        closeButtonClassName: '!top-6 !right-6',
        className: '!p-6',
      },
    )
  }

  if (!hadOcrChecks || isLoading || !flags.allSection) return null

  return (
    <>
      {isUpdating && (
        <FrankieLoader
          size="md"
          fullscreen
          className="!z-[100]"
          loaderClassName="gap-3"
          label={t('updateCheckStatus')}
          loading={isUpdating}
        />
      )}
      <CommentHOC
        entityId={entityId}
        commentType={CommentsTypes.IDV_BIOMETRICS}
        className="!h-min"
        processId={processResultIds[0]}
      >
        <div
          id="IDV_BIOMETRIC"
          ref={ref}
          className={`border rounded-1.5 flex flex-col gap-4 pt-5 px-6 pb-6 ${borderStyleConfig(
            status,
          )}`}
        >
          <div className="text-xl text-tertiary-grey-800 gap-3 font-bold flex items-center">
            <IconConfig type={status} />
            {t('title')}
            <ManualStatusBadge
              status={manualStatus}
              textFailed={t('manualFail')}
              textPassed={t('manualPass')}
            />
            {canOverrideStatus && requireResolve && (
              <FrankieButton
                noStyles
                onClick={handleClick}
                className="ml-auto text-sm text-primary-800 font-semibold"
              >
                {t('resolve')}
              </FrankieButton>
            )}
            <CommentToolTip
              className="!z-50 mt-0.5"
              commentType={CommentsTypes.IDV_BIOMETRICS}
              entityId={entityId}
              processId={processResultIds[0]}
            />
          </div>
          <div
            className={cv(
              'grid grid-container auto-rows-min gap-4',
              data?.isSidebarOpen && 'open',
            )}
          >
            <IndividualOcrGallery entityId={entityId} gallery={gallery} />
            {flags.comparison && (
              <>
                <OcrDocumentRow ocrResult={ocrCheckResults.comparisonValues} />
                {flags.liveness && (
                  <FrankieDivider className="my-4 h-min col-span-2 col-start-2" />
                )}
              </>
            )}
            {flags.liveness && (
              <OcrDocumentRow ocrResult={ocrCheckResults.livenessValues} />
            )}
          </div>
        </div>
      </CommentHOC>
    </>
  )
}
